<template>
	<div class="rule">
		<p>根据国家相关法律、法规的规定及厦门国际商品拍卖有限公司（以下简称“厦门国拍网”或“国拍网”）有关规则，本着公开、公平、公正、自愿、诚实信用的原则，参加拍卖的意向竞买人（甲方）和国拍网（乙方）共同遵守以下协议：</p>
		<p>1.国拍网实行注册会员制度。依照适用法律能够合法签署具有法律约束力合同的个人可以注册成为国拍网的会员。未满十八周岁或不具有完全民事行为能力的个人不可注册为本网站的正式会员。个人申请注册，必须提供有效的身份证件、常住地址、手机号码及其他联系电话和电子邮件地址等材料。注册的用户名应符合法律规定、不违背社会公序良俗，否则乙方有权拒绝其注册及竞买。</p>
		<P>2.用户一经注册或使用国拍网服务即视为对本协议所有条款的理解和接受。国拍网有权在必要时单方修改或变更本协议的内容，并将通过国拍网网站（地址：www.Xmgp.Vip）公布最新的协议，不另作个别通知。若甲方于任何修改或变更本协议条款后继续使用国拍网服务，则视为甲方已阅读、了解并同意接受修改或变更，若甲方不同意，则甲方应主动立即停用国拍网服务。
</P>
		<P>3.甲方使用国拍网的服务须通过乙方的实名认证，并对需认证信息的真实性、合法性、有效性、完整性等承担法律责任。甲方提供的实名认证信息有任何错误、虚假、失效、过期或不完整的情况，乙方有权立即停止甲方的使用权限，并视情况保留追究甲方法律责任的权利,造成乙方或乙方委托人经济损失，甲方自行全部承担。
</P>
		<P>4.甲方使用国拍网的服务，竞得/拍得相关标的，应及时按照乙方或乙方委托人要求履约，如发生违约造成乙方或乙方委托人损失（包括但不限于名誉、经济等损失）甲方自行全部承担，乙方或乙方委托人有权向甲方追偿。</P>
		<P>5.甲方应妥善保管自己的账户、密码等信息，防止泄漏。甲方由于自己的原因而致使账户、密码失密造成的损失由甲方自行全部承担。
</P>
		<P>6.甲方须保证提供给乙方的所有信息均真实有效，手机等通讯联系方式畅通，在接受乙方告知等信息后及时反馈，因甲方原因造成信息无法传达的责任自负。
</P>
		<P>7.甲方保证不利用技术或其他手段攻击乙方网络、破坏乙方系统，否则甲方承担由此给乙方或任何第三方造成的损失。
</P>
		<P>8.乙方对相关电子数据进行保留并作为对甲方提供服务行为的证明（保留5年）。</P>
		<p>9.乙方对甲方的账户资料、委托事项和国拍网服务密码负有保密义务，未经甲方许可，乙方不得透露甲方在乙方的任何信息资料。但乙方按照有关法律、法规和规章的规定或有关司法机关、行政管理机关的要求提供甲方的有关资料不在此限。
</p>
		<p>10.暂停、中止或终止国拍网服务 （一）甲方同意，如乙方基于自身独立判断或第三方举报或投诉，认为甲方已经违反任何国家法律、法规的规定或本协议任何条款，乙方有权暂停、中止或终止向甲方提供在国拍网上的服务。 （二）甲方同意，发生下列情形之一的，乙方无需进行事先通知即可暂停、中止或终止向甲方提供国拍网服务, 并不承担由此带来的任何损失。 （1）甲方提供的个人资料不真实，且未能提供合理说明及证明； （2）甲方违反相关法律、法规、规章及政府规范性文件或本协议约定； （3）黑客攻击； （4）乙方根据标的委托方的合理要求； （5）乙方认为存在安全隐患。</p>
		<p>
			11.国拍网账户及其密码和安全性甲方了解并同意,甲方可通过国拍网账户使用国拍网服务，并确保上述账户及其密码的安全。甲方将对利用上述账户及其密码所进行的一切行为负完全责任，并同意以下事项： （一）甲方不对其他任何人泄露甲方的国拍网账户、密码和安全证书等身份信息，亦不得使用其他任何人的国拍网账户、密码和安全证书等身份信息。所有通过甲方的国拍网账户和密码进行的任何操作、发出的任何指令均视为甲方本人做出。 （二）甲方同意如发现有第三人冒用或盗用甲方的国拍网账户、密码和安全证书或其他任何未经甲方合法授权使用的情形，应立即以有效方式通知国拍网，国拍网在接受甲方的有效通知前，对第三人使用国拍网服务已发生的效力，将不承担任何责任。 （三）当甲方的国拍网账户使用完毕后，甲方应安全退出。如因甲方未按照本协议的约定使用国拍网账户、保管不善等原因导致的盗号、密码失窃、个人信息泄露等，由甲方自行承担相关的损失。 （四）因甲方泄露国拍网账户交易密码、验证码等身份认证信息或介质保管不善、未尽到防范风险与保密义务或因甲方引起的其他原因（包括但不限于点击钓鱼链接，泄露交易密码或信息，甲方使用的计算机被他人侵入等情况）导致的损失由甲方自行承担。
		</p>
		<p>12.乙方拥有向用户提供网络服务过程中所产生并储存于服务器中的所有数据信息的所有权，拥有国拍网内容的著作权、网站域名权利以及商业秘密、技术秘密等知识产权，任何人不得非法使用以上信息和权利，否则须承担相应的法律责任。</p>
		<p>13.甲方清楚地了解使用国拍网可能遭受的风险，并自愿承担由该种风险而导致的损失。甲方注册之前，已经详细阅读了本协议包括乙方免责条款在内的所有条款，并已准确理解其含义。</p>
		<p>14.在本协议履行过程中，因下列情形导致甲方损失的，乙方不承担责任： a.因地震、火灾、台风及其他不可抗力引起停电、网络系统故障、电脑故障； b.因电信部门的通讯线路故障、通讯技术缺陷、电脑黑客或计算机病毒等问题造成委托系统不能正常运转； c.法律和政策重大变化或乙方不可预测和不可控制因素导致的突发事件； d.因通讯、网络中断、堵塞等情况致使系统无法正常使用； e.因设备或通讯故障致使双方信息未能及时交换的； f.因甲方对设备的错误操作和对有关信息的错误理解导致甲方损失的； g.因甲方的故意或疏忽导致国拍网服务密码泄露或遗失，由此导致甲方损失的； h.法律规定和本协议约定的其他乙方免责事项。</p>
		<p>15.发生下述情况时协议终止，至终止情形发生时终止。 1. 双方书面同意终止； 2. 一方违约，另一方书面通知对方终止本协议； 3. 因不可抗力使本协议无法继续履行。</p>
		<p>16.甲、乙双方如有争议，应尽可能通过协商、调解解决，协商、调解不成，向乙方实际经营地的法院起诉。</p>
		<p class="textBold">17.本协议自甲方在互联网上注册完成之日起生效，注册即视为书面同意以上全部条款。</p>
		
	</div>
</template>

<script>
	export default{
		
		dtat(){
			return{
				
			}
		},
	}
</script>

<style lang="less" scoped>
	.rule{
		p{
			padding-bottom: 20px;
			line-height: 20px;
		}
		.textBold{
			// color: #000;
			font-weight: bold;
		}
	}
</style>