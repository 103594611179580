<template>
	<div class="register">
		<loginHeader :type='0'></loginHeader>
		<div class="bg-white">
			<div class="container">
				<div class="title">{{title}}</div>
				<!-- 个人用户注册 -->
				<div class="register-container" v-if="type == 1">
					<div class="pinless personal-one">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
							<!-- 手机号 -->
							<el-form-item label="手机号：" prop="phone" required>
								<el-input v-model="ruleForm.phone" maxlength="11" placeholder="请输入要绑定的手机号"
									class="phone-num" id="phone" clearable>
								</el-input>
							</el-form-item>

							<!-- 验证码 -->
							<el-form-item label="验证码：" prop="code" required>
								<el-input class="code-input" id="code" v-model="ruleForm.code" placeholder="请输入手机验证码" clearable>
								</el-input>
								<p class="obtain-code" @click="getSms">{{smsTxt}}</p>
							</el-form-item>

							<!-- 设置密码 -->
							<el-form-item label="设置密码：" prop="pwd" required>
								<el-input v-model="ruleForm.pwd" placeholder="请记住密码" type="password" maxlength="20" id="pwd" clearable>
								</el-input>
							</el-form-item>

							<!-- 重复密码 -->
							<el-form-item label="重复密码：" prop="repwd" required>
								<el-input v-model="ruleForm.repwd" placeholder="确认您的密码" type="password" maxlength="20" id="repwd" clearable>
								</el-input>
							</el-form-item>

							<el-form-item prop="checked" class="label_l text_align_left">
								<el-checkbox v-model="ruleForm.checked">我已阅读并同意</el-checkbox>
								<div class="agreement" @click="centerDialogVisible = true">《厦门国拍网拍卖规则》</div>
							</el-form-item>

							<el-form-item class="label_l">
								<el-button @click="submitForm('ruleForm')">注册</el-button>
							</el-form-item>
						</el-form>

						<!-- 二维码 -->
						<!-- <div class="qrCode">
							<img src="../../assets/img/login/zpxzs.png" />
							<h3>扫码关注中拍平台</h3>
						</div> -->

					</div>
				</div>

				<!-- 企业用户注册 -->
				<div class="register-container company" v-if="type == 2">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
						<!-- 手机号 -->
						<el-form-item label="手机号：" prop="phone" required>
							<el-input v-model="ruleForm.phone" maxlength="11" placeholder="请输入您的联系电话" class="phone-num" id="phone" clearable>
							</el-input>
						</el-form-item>

						<!-- 手机验证码 -->
						<el-form-item label="验证码：" prop="code" required>
							<el-input class="code-input" id="code" v-model="ruleForm.code" placeholder="请输入验证码" clearable>
							</el-input>
							<p class="obtain-code" @click="getSms">{{smsTxt}}</p>
						</el-form-item>

						<!-- 设置密码 -->
						<el-form-item label="设置密码：" prop="pwd" required>
							<el-input v-model="ruleForm.pwd" placeholder="请记住密码" type="password" maxlength="20" id="pwd" clearable>
							</el-input>
						</el-form-item>

						<!-- 重复密码 -->
						<el-form-item label="重复密码：" prop="repwd" required>
							<el-input v-model="ruleForm.repwd" placeholder="确认您的密码" type="password" maxlength="20" id="repwd" clearable>
							</el-input>
						</el-form-item>

						<el-form-item prop="checked" class="label_l text_align_left">
							<el-checkbox v-model="ruleForm.checked">我已阅读并同意</el-checkbox>
							<!-- <router-link to="" class="agreement">《厦门国拍网拍卖规则》</router-link> -->
							<div class="agreement" @click="centerDialogVisible = true">《厦门国拍网拍卖规则》</div>
						</el-form-item>

						<el-form-item class="label_l">
							<el-button @click="companySubmitForm('ruleForm')">注册</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		
		<!-- 弹出层 -->
		<el-dialog title="厦门国拍网拍卖规则" :visible.sync="centerDialogVisible" width="66%" class="rulePage" center>
			<rulePage></rulePage>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="centerDialogVisible = false">同意并继续</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import loginHeader from '@/components/login/loginHeader.vue'
	import rulePage from './rule.vue'

	import {
		sendSms,
		register,
		eRegister
	} from '@/axios/api.js'
	export default {
		data() {
			var validatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else if (!this.checkAccount()) {
					callback(new Error('请输入正确的手机号码'));
				} else {
					callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.repwd !== '') {
						this.$refs.ruleForm.validateField('repwd');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.pwd) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			var validateChecked = (rule, value, callback) => {
				if (value === false) {
					callback(new Error('请同意拍卖规则'));
				} else {
					callback();
				}
			};
			return {
				title: '',
				type: this.$route.query.type,
				getSmsStatus: false,
				smsNum: 60,
				smsTxt: '获取验证码',
				centerDialogVisible: false,
				ruleForm: {
					phone: '',
					code: '',
					pwd: '',
					repwd: '',
					checked: false
				},
				rules: {
					phone: [{
						validator: validatePhone,
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
					pwd: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					repwd: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
					checked: [{
						validator: validateChecked,
						trigger: 'change'
					}],
				},
			};
		},
		components: {
			loginHeader,
			rulePage
		},
		created() {
			let type = this.$route.query.type
			if (type == 1) {
				this.title = '个人用户注册'
				return
			}
			if (type == 2) {
				this.title = '企业用户注册'

			}
		},
		methods: {
			// 个人用户注册
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let that = this
						let obj = {
							mobile: this.ruleForm.phone,
							code: this.ruleForm.code,
							pwd: this.ruleForm.pwd,
							repwd: this.ruleForm.repwd
						}
						register(obj).then(function(res) {
								console.log('注册:', res.data)
								let data = res.data
								if (data.success == true) {
									that.$message({
										message: data.msg,
										type: 'success'
									});
									that.resetForm()									
									that.$router.push('/login');
								} else {
									that.$message({
										message: data.msg,
										type: 'error'
									})
								}
							})
							.catch(function(err) {
								console.log('register err:', err)
							})
						// 是否钓选协议
						// if (this.checked) {} else {
						// 	this.$message({
						// 		message: '请同意注册协议',
						// 		type: 'error'
						// 	})
						// }
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 企业用户注册
			companySubmitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let that = this
						let obj = {
							mobile: this.ruleForm.phone,
							code: this.ruleForm.code,
							pwd: this.ruleForm.pwd,
							repwd: this.ruleForm.repwd
						}
						eRegister(obj).then(function(res) {
								console.log('企业注册:', res.data)
								let data = res.data
								if (data.code == 0) {
									that.$message({
										message: `恭喜你，${data.msg}`,
										type: 'success'
									});
									that.resetForm()
									that.$router.push('/login');
								} else {
									that.$message({
										message: data.msg,
										type: 'error'
									})
								}
							})
							.catch(function(err) {
								console.log('register err:', err)
							})
						// if (this.checked) {

						// } else {
						// 	this.$message({
						// 		message: '请同意注册协议',
						// 		type: 'error'
						// 	})
						// }
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 提交完清空
			resetForm() {
				this.$refs.ruleForm.resetFields();
			},
			//验证手机号
			checkAccount() {
				let type = this.$route.query.type
				let phone = this.ruleForm.phone
				if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone) && phone.toString().length == 11) {
					return true
				} else {
					return false
				}
			},
			//获取验证码
			getSms() {
				if (this.getSmsStatus) {
					return
				}
				if (!this.checkAccount()) {
					this.$message({
						message: '请输入正确的手机号码',
						type: 'error'
					})
					return
				}

				let that = this
				let obj = {
					mobile: this.ruleForm.phone
				}
				sendSms(obj).then(function(res) {
						console.log('sendSMS:', res.data)
						let data = res.data
						if (data.code == 0) {
							that.$message({
								message: data.msg,
								type: 'success'
							})
							that.getSmsStatus = true
							that.timeObj = setInterval(function() {
								if (that.smsNum == 1) {
									clearInterval(that.timeObj)
									that.smsNum = 60
									that.getSmsStatus = false
									that.smsTxt = '获取验证码'
								} else {
									that.smsNum--
									that.smsTxt = `${that.smsNum}s`
								}
							}, 1000)
						} else {
							that.$message({
								message: data.msg,
								type: 'error'
							})
						}
					})
					.catch(function(err) {
						console.log('sendSMS err:', err)
					})
			},
			
		}
	}
</script>

<style lang="less" scoped>
	.register {
		min-height: 100vh;
		background: #fff;

		.title {
			font-size: 22px;
			font-weight: 500;
			padding: 30px 0 50px 0;
		}

		.register-container-header {
			font-size: 18px;
			width: 100%;
			height: 82px/9;
			margin-bottom: 100px;
		}
	}

	.pinless {
		margin: 0 0 100px 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.qrCode {

			// border-left: solid 1px #b9b9b9;
			img {
				width: 160px;
				margin: -20px 50px 10px;
			}

			h3 {
				text-align: center;
				font-size: 16px;
				font-weight: 500;
			}
		}
	}

	/deep/.el-form {
		padding-right: 50px;
		position: relative;

		// &::before {
		// 	position: absolute;
		// 	top: 0;
		// 	right: 0;
		// 	width: 1px;
		// 	height: 366px;
		// 	background: #b9b9b9;
		// 	content: "";
		// }

		.el-form-item {
			width: 335px;
			position: relative;
			border-bottom: solid 1px #ccc;
		}

		.el-form-item__label {
			text-align: left;
			padding-right: 0;
		}

		.el-input__inner {
			border: none;
		}

		.code-input {
			.el-input__inner {
				padding-right: 80px;
			}
		}

		.label_l {
			border-bottom: none;
			text-align: center;

			.el-form-item__content {
				margin-left: 0 !important;
			}
		}
		.text_align_left{
			text-align: left;
			.el-form-item__content {
				display: flex;
			}
		}
		.el-checkbox {
			color: #333;

		}

		.el-checkbox__input.is-checked+.el-checkbox__label {
			color: #333;
		}

		.agreement {
			color: #d6363b;
		}

		.el-button {
			width: 335px;
			height: 48px;
			background: #f13f48;
			color: #fff;
			line-height: 48px;
			border-radius: 5px;
			display: inline-block;
			margin-bottom: 30px;
			padding: 0;
			// span{
			// 	opacity: .6;
			// }
			// .opacity{
			// 	opacity: 1;
			// }
		}

		.obtain-code {
			color: #c93e45;
			cursor: pointer;
			width: 80px;
			text-align: center;
			position: absolute;
			top: 0;
			right: 0;

			&::before {
				width: 1px;
				height: 18px;
				background: #EBEBEB;
				position: absolute;
				top: 50%;
				right: 94px;
				transform: translate(0, -50%);
				content: "";
			}
		}
	}

	.company {
		.el-form {
			padding-right: 0px;
			margin: 0 auto;

			&::before {
				width: 0px;
			}

			.el-form-item {
				margin: 0 auto 22px;
			}
		}
	}
	.rulePage{
		/deep/.el-dialog{
			.el-dialog__body{
				height: 345px;
				overflow: auto;
			}
			.el-dialog__footer{
				padding: 10px 20px;
			}
			.el-button--primary {
				color: #FFF;
				background-color: #f13f48;
				border-color: #f13f48;
			}
		}
	}
	// 提示弹窗
	// /deep/.el-dialog {
	// 	width: 420px;

	// 	.el-button--primary {
	// 		color: #FFF;
	// 		background-color: #f24b53;
	// 		border-color: #f24b53;
	// 	}

	// 	.el-button--default {

	// 		&:focus,
	// 		&:hover {
	// 			color: #f24b53;
	// 			border-color: #f24b53;
	// 			background-color: #fff;
	// 		}
	// 	}
	// 	.el-button+.el-button {
	// 	    margin-left: 20px;
	// 	}
	// }
</style>
